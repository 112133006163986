<template>
  <div class="home d-flex">
    <InConstruction />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import InConstruction from "@/components/InConstruction.vue"; // @ is an alias to /src

@Component({
  components: {
    InConstruction,
  },
})
export default class InConstructionView extends Vue {}
</script>

<style lang="scss">
.home {
  flex: 1;
  align-items: center;
  flex-direction: column;
}
</style>
