<template>
  <v-container>
    <v-row>
      <v-col class="text-align-left text-body" cols="12" lg="7">
        <slot name="explanation"></slot>
      </v-col>
      <v-col class="d-flex align-start mb-4" cols="12" lg="5">
        <slot name="image"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component
export default class SpeechTherapyService extends Vue {
  // Data

  // Lifecycle

  // Interface methods
  // Getters & setters
  // Helper methods
}

</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

</style>
