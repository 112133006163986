<template>
  <speech-therapy-service>
    <template v-slot:explanation>
      <h2 class="hidden-sm-and-down">Articulatiestoornissen</h2>
      Tijdens de spraakontwikkeling leren kinderen stap voor stap klanken vormen en uitspreken, om deze vervolgens ook
      te gebruiken in woorden.
      Op vijfjarige leeftijd zouden alle klanken verworven moeten zijn.
      Als er na die leeftijd nog steeds problemen zijn met bepaalde spraakklanken, spreken we van een
      articulatiestoornis.

      <br/>
      <br/>
      Hierbij wordt een onderscheid gemaakt tussen 3 verschillende articulatiestoonissen:
      <ul class="articulation-disablities-ul" style="align-self: baseline">
        <li>Fonetische articulatiestoornis</li>
        <p>
          Dit is een stoornis waarbij het kind de klank kan maken en ook bedoelt, maar de klank technisch niet correct
          maakt en dus vervormd klinkt.
        </p>
        <li>Fonologische articulatiestoornis</li>
        <p>
          Dit is een stoornis waarbij het probleem niet ligt bij de productie van spraakklanken, maar wel bij het
          correct gebruiken van deze klanken bij het vormen van woorden.
        </p>
        <li>Spraakontwikkelingsdyspraxie (SOD)</li>
        <p>
          Dit is een complexe spraakstoornis waarbij het plannen van de spraakbewegingen is aangetast.
          Het kind weet wat het wil zeggen en de spieren weten hoe ze moeten bewegen, maar het overbrengen van het
          juiste plan van de hersenen naar de mond verloopt moeizaam.
        </p>
      </ul>
    </template>
    <template v-slot:image>
      <v-img position="center" src="@/assets/Articulatie.jpeg"></v-img>
    </template>
  </speech-therapy-service>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import SpeechTherapyService from "@/components/SpeechTherapyService.vue";

@Component({
  components: {SpeechTherapyService}
})
export default class STLearningDisabilities extends Vue {
  // Data

  // Lifecycle

  // Interface methods
  // Getters & setters
  // Helper methods
}

</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

.articulation-disablities-ul {
  align-self: baseline;

  li {
    text-decoration: underline;
    margin-bottom: 0.5rem;
  }
}


</style>
