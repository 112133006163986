<template>
  <div>
    <div class="home d-flex">
      <!--    <InConstruction />-->
      <Landing/>
    </div>
    <v-container class="mb-8">
      <ServicesOnHome/>
      <WhenToContactSpeechTherapistsOnHome/>
      <OurTeamOnHome/>
    </v-container>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import InConstruction from "@/components/InConstruction.vue";
import Landing from "@/components/Landing.vue";
import STArticulationDisabilities from "@/components/STArticulationDisabilities.vue";
import ServicesOnHome from "@/components/ServicesOnHome.vue";
import WhenToContactSpeechTherapistsOnHome from "@/components/WhenToContactSpeachTherapistsOnHome.vue";
import OurTeamOnHome from "@/components/OurTeamOnHome.vue";

@Component({
  components: {
    OurTeamOnHome,
    ServicesOnHome,
    STArticulationDisabilities,
    Landing,
    InConstruction,
    WhenToContactSpeechTherapistsOnHome
  },
})
export default class HomeView extends Vue {
}
</script>

<style lang="scss">
@import "@/scss/index.scss";

:host {
  min-height: calc(100vh - 25px);
}

.home {
  flex: 1;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - $navbar-height);
  background: $pkt-orange-light-0-opacity-09;

  @media only screen and (max-width: $small-screen-bp) {
    //height: 940px;
  }
}
</style>
