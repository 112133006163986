<template>
  <v-app id="app" class="d-flex">
    <!-- <v-app-bar app flat color="transparent" prominent>
      <v-img class="mx-10" max-height="6rem" max-width="6rem" :src="require('@/assets/IconLogo.png')"></v-img>

      <v-toolbar-title class="text-h3 align-self-center font-weight-bold">{{ appName }}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar> -->
    <div class="header-container">
      <Navbar :app-name="appName"/>
    </div>
    <div style="min-height: calc(100vh - 25px) !important;">
      <router-view/>
    </div>
    <div class="footer-container">
      <Footer :app-name="appName"></Footer>
    </div>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

@Component({components: {Footer, Navbar, Header: Navbar}})
export default class App extends Vue {
  appName = "Praktijk KeiTof!";
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

#app {
  font-family: $font-family-accent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.header-container {
  border-bottom: 1px solid $pkt-orange;
  background-color: $pkt-orange-light-0-opacity-09 !important;
}

.footer-container {
  width: 100%;
  font-size: 1rem;
  color: $pkt-blue;
  border-top: 1px solid $pkt-orange !important;
}
</style>
