<template>
  <div>
    <h1 class="homepage-title my-4">Ons team</h1>
    <div class="our-team-container">
      <ProfileOnHome
          :photo-url="imageNames.NathalieDePalmaProfile"
          aboutUsId="nathalie_de_palma"
          class="my-8"
          job="Zaakvoerster - logopediste"
          name="Nathalie De Palma"
          photo-position="center"
      ></ProfileOnHome>
      <ProfileOnHome
          :photo-url="imageNames.SofieEvensProfile"
          aboutUsId="sofie_evens"
          class="my-8"
          job="Zaakvoerster - logopediste"
          name="Sofie Evens"
      ></ProfileOnHome>
      <ProfileOnHome
          :photo-url="imageNames.LienGeurtsProfile"
          aboutUsId="lien_geurts"
          class="my-8"
          job="Logopediste"
          name="Lien Geurts"
      ></ProfileOnHome>
      <ProfileOnHome
          :photo-url="imageNames.AnkeArmaniProfile"
          aboutUsId="anke_armani"
          class="my-8"
          job="Logopediste"
          name="Anke Armani"
      ></ProfileOnHome>
      <ProfileOnHome
          :photo-url="imageNames.EvelienLeursProfile"
          aboutUsId="evelien_leurs"
          class="my-8"
          job="Psychologe"
          name="Evelien Leurs"
      ></ProfileOnHome>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import ProfileOnHome from "@/components/ProfileOnHome.vue";
import {imageNames} from "@/assets/ImageNames";

@Component({
  computed: {
    imageNames() {
      return imageNames
    }
  },
  components: {
    ProfileOnHome
  }
})
export default class OurTeamOnHome extends Vue {
  // Data

  // Lifecycle

  // Interface methods

  navigateToPerson(id: string) {
    console.log(id)
    this.$router.push({path: `/over-ons`})
  }

  // Getters & setters
  // Helper methods
}
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

.our-team-container {
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: space-evenly;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 2rem;

  @media only screen and (min-width: $x-large-screen-bp) {
    grid-template-columns: repeat(4, 250px);
  }

  @media only screen and (max-width: $small-screen-bp) {
    grid-template-columns: 1fr;

  }
}
</style>
