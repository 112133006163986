<template>
  <div class="header-wrapper">
    <v-app-bar :height="6 * 16" :prominent="isMobile" app class="header" color="transparent" flat>
      <router-link class="logo-container d-flex" to="/">
        <v-img :src="require('@/assets/IconLogo.png')" class="mx-1 mx-md-8 header-logo-img"></v-img>
        <v-toolbar-title class="text-h4 align-self-center font-weight-bold"
                         style='color: #2c3e50;font-family: "Josefin Slab", serif !important;"'>
          {{ appName }}
          <br/>
          <div class="body-1">
            <span class="entrepreneur-names">Nathalie De Palma & Sofie Evens</span>
          </div>
        </v-toolbar-title>
      </router-link>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="navbar-item-container hidden-md-and-down">
        <router-link v-for="navItem in navItems" :key="navItem.name" :to="navItem.routeName" class="navbar-item">
          <span style="font-family: 'Josefin Slab', serif !important">{{ navItem.name }}</span>
        </router-link>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed right temporary>
      <v-list dense nav>
        <v-list-item-group v-model="group">
          <v-list-item v-for="navItem in navItems" :key="navItem.name" :to="navItem.routeName">
            <v-list-item-title style="font-size: 1rem;">
              <a style="font-family: 'Josefin Slab', serif !important">{{ navItem.name }}</a>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts" scoped>
import {Component, Prop, Vue} from "vue-property-decorator";
import {INavItem} from "@/models/models";

@Component
export default class Navbar extends Vue {
  @Prop({required: true}) public readonly appName!: string;
  group = null;
  drawer = false;
  fontFamily = 'Josefin Slab';

  navItems: INavItem[] = [
    {name: "Logopedie", routeName: "logopedie"},
    {name: "Psychologie", routeName: "psychologie"},
    // {name: "Huiswerkbegeleiding", routeName: "huiswerk-begeleiding"},
    {name: "Typ10", routeName: "typ10"},
    {name: "Tarieven & afspraken", routeName: "tarieven-en-afspraken"},
    {name: "Over ons", routeName: "over-ons"},
    {name: "Contact", routeName: "contact"},
    {name: "Vacatures", routeName: "vacature"},
  ];

  public get isMobile() {
    return !this.$vuetify.breakpoint.mdAndUp;
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

.v-toolbar__content {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    padding: 0 !important;
  }
}

.header-wrapper {
  position: relative;
  max-width: 1500px;
  margin: 0 auto !important;
  background-color: $pkt-orange-light-0-opacity-05 !important;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    max-width: 100%;
    width: 100%;
  }

  .header {
    position: relative;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding-top: 1rem
    }


    .logo-container {
      text-decoration: none;

      .header-logo-img {
        @media #{map-get($display-breakpoints, 'md-and-up')} {
          max-height: 4rem !important;
          max-width: 4rem !important;
        }
        max-height: 3rem !important;
        max-width: 3rem !important;
      }

      .header-title {
        max-height: 1rem;
        max-width: 1rem;
        //z-index: 1;
      }
    }

    .entrepreneur-names {
      font-family: "Josefin Slab", serif !important;
      font-size: 1rem !important;
      font-weight: 500;

      @media #{map-get($display-breakpoints, 'md-and-down')} {
        font-size: 1rem !important;
      }
    }

    .navbar-item-container {
      flex: 1;
      height: 100%;
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-end;
      align-items: center;
      justify-items: center;
      grid-template-columns: repeat(7, 1fr);

      .navbar-item {
        padding: 0 1rem;
        font-size: 1.25rem;
        font-weight: 700;
        text-decoration: none;
        height: 100%;
        display: grid;
        align-items: center;
        justify-items: center;
        border-bottom: 3px solid transparent;

        span {
          white-space: nowrap;
        }
      }

      .navbar-item:hover {
        border-bottom: 3px solid $pkt-blue;
        border-radius: 2px;
        transition: background-color 0.2s ease-in-out, height 0.2s ease-in-out;
      }
    }
  }
}
</style>
