<template>
  <div class="footer-container">
    <v-footer bottom color="transparent">
      <v-col class="logo" cols="12" lg="4">
        <router-link class="logo-container d-flex" to="/">
          <v-img :src="require('@/assets/IconLogo.png')" class="mx-1 mx-md-8 header-logo-img" max-height="64px"
                 max-width="64px"></v-img>
          <div class="text-h4 align-self-center font-weight-bold"
               style='color: #2c3e50;font-family: "Josefin Slab", serif !important;"'
          >
            {{ appName }}
            <br/>
            <div class="body-1">
              <span class="entrepreneur-names">Nathalie De Palma & Sofie Evens</span>
            </div>
          </div>
        </router-link>
      </v-col>
      <v-col class="footer-info" col cols="12" lg="8">
        <span class="footer-item">
          <v-icon :color="iconColor"
                  class="mr-4">mdi-email</v-icon>nathaliedepalma@hotmail.com</span>
        <span class="footer-item">
          <v-icon :color="iconColor" class="mr-4">mdi-phone</v-icon>0032 / 471 89 63 17</span>
        <span class="footer-item">
          <v-icon
              :color="iconColor" class="mr-4">mdi-map-marker</v-icon>Koning Albertlaan 46 C/1, <span
            class="d-inline-block">3680 Maaseik</span></span>
      </v-col>
      <v-col class="text-center text-h" cols="12" style="font-size: 1.2rem !important;">
        <strong style='color: #2c3e50;font-family: "Josefin Slab", serif !important;"'>{{ appName }} -
          {{ new Date().getFullYear() }}</strong>
        <br class="hidden-md-and-up"/>
        <span class="hidden-sm-and-down"> - </span>
        <span class="footer-address">Koning Albertlaan 46 C/1, <span
            class="footer-address">3680 Maaseik</span></span>
        <span class="hidden-md-and-down"> - </span>
        <a class="footer-gdpr" href="/GDPR.pdf">GDPR</a>
      </v-col>
    </v-footer>
  </div>
</template>

<script lang="ts" scoped>
import {Component, Prop, Vue} from "vue-property-decorator";
import {INavItem} from "@/models/models";

@Component
export default class Footer extends Vue {
  @Prop({required: true}) public readonly appName!: string;
  group = null;
  drawer = false;
  fontFamily = 'Josefin Slab';
  iconColor = "#E4BB8A";

  navItems: INavItem[] = [
    {name: "Logopedie", routeName: "logopedie"},
    {name: "Huiswerkbegeleiding", routeName: "huiswerk-begeleiding"},
    {name: "Typ10", routeName: "typ10"},
    {name: "Tarieven & afspraken", routeName: "tarieven-en-afspraken"},
    {name: "Over ons", routeName: "over-ons"},
    {name: "Contact", routeName: "about"},
  ];

  public get isMobile() {
    return !this.$vuetify.breakpoint.mdAndUp;
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

.footer-container {
  background-color: $pkt-orange-light-0-opacity-09 !important;
}

.v-toolbar__content {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    padding: 0 !important;
  }
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  text-decoration: none !important;

  .header-logo-img {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      max-height: 4rem !important;
      max-width: 4rem !important;
    }
    max-height: 3rem !important;
    max-width: 3rem !important;
  }

  .header-title {
    max-height: 1rem;
    max-width: 1rem;
    //z-index: 1;
  }
}

.entrepreneur-names {
  font-family: "Josefin Slab", serif !important;
  font-size: 1rem !important;
  font-weight: 500;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    font-size: 1rem !important;
  }

}

.footer-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    justify-items: center;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: 1fr;
  }
}

.footer-item {
  display: block;
  margin: 0.5rem 0;
  text-align: center;
}

.footer-address {
  color: #2c3e50;
  font-family: "Josefin Slab", serif !important;

}

.footer-gdpr {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    display: block;
  }
}
</style>
