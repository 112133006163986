<template>
  <div id="in-construction-component">
    <div class="overlay">
      <div class="text-container px-4">
        <span class="text-h4 text-md-h2 font-weight-bold slogan"> LEER, REFLECTEER EN DENK VOORUIT </span>
        <span class="text-h5 text-md-h3 font-weight-bold">
          Deze website is nog in ontwikkeling.<br />
          Kom binnenkort nog eens een kijkje nemen!
        </span>
      </div>
    </div>
    <div class="img-container"></div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    msg: String,
  },
})
export default class HelloWorld extends Vue {
  msg!: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/scss/index.scss";

#in-construction-component {
  display: flex;
  flex: 1;
  align-items: center;
}

.img-container {
  background-position: center !important;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
  display: flex;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    background: url("@/assets/InConstruction.jpg");
    background-size: cover;
  }

  background: url("@/assets/InConstruction-mobile.jpg");
  background-size: 100% 100%;

  // z-index: -1;
}

.slogan {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-bottom: 150px;
  }
  margin-bottom: 64px;
}

.overlay {
  // background: rgba(0, 0, 0, 0.5);
  // background-color: rgba(0, 0, 0, 0.5);
  // position: fixed;
  // width: 100%;
  // height: 100%;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  z-index: 2;
  // display: flex;
}

.text-container {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  opacity: 1;
  font-weight: bold;
  // color: $primary-color;
}
</style>
