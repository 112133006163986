export const $font_family = 'Montserrat';
export const $font_family_accent = 'Josefin Slab';
export const $body_font_family = "'Josefin Slab', serif";
export const $font_size = "16px";
export const $font_size_mobile = "16px";
export const $text_color = "#36405a";
export const $heading_font_family = "'Josefin Slab', serif !important";
export const $white_transparent_03 = "rgba(255, 255, 255, 0.3)";
export const $pkt_orange_light_0 = "rgba(248, 237, 223, 1)";
export const $pkt_orange_light_0_opacity_05 = "rgba(248, 237, 223, 0.5)";
export const $pkt_orange_light_0_opacity_07 = "rgba(248, 237, 223, 0.7)";
export const $pkt_orange_light_0_opacity_09 = "rgba(248, 237, 223, 0.9)";
export const $pkt_orange = "#E4BB8A";
export const $pkt_blue = "#3a6061";
export const $white = "#ffffff";

export const $navbar_height = "97px";
export const $footer_height = "64px";


export const smallScreenBp = 600;
export const mediumScreenBp = 960;
export const largeScreenBp = 1264;
export const xLargeScreenBp = 1904;
export const $small_screen_bp = `${smallScreenBp}px`;
export const $medium_screen_bp = `${mediumScreenBp}px`;
export const $large_screen_bp = `${largeScreenBp}px`;
export const $x_large_screen_bp = `${xLargeScreenBp}px`;