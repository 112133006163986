<template>
  <div class="blockquote-item">
    <span class="quote-symbol">&#8220;</span>
    <div class="blockquote">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component
export default class Quote extends Vue {
  // Data

  // Lifecycle


  // Interface methods
  // Getters & setters
  // Helper methods
}
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

.blockquote-item {
  display: grid;
  grid-template-rows: 2.5rem auto;
  background-color: $pkt-orange-light-0-opacity-09;
  border-radius: 10px;
  align-items: center;
  justify-items: center;

  .quote-symbol {
    font-size: 4rem;
  }

  .blockquote {
    padding: 1rem 1.5rem;
    font-weight: 500 !important;
    text-align: center;
  }
}


</style>
