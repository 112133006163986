<template>
  <div>
    <h1 class="homepage-title my-4">Wanneer moet je contact opnemen met een logopediste?</h1>
    <v-row class="homepage-container">
      <v-col>
        Kinderen van alle leeftijden kunnen bij ons in de praktijk terecht.<br>
        Ook voor peuters en kleuters kan het noodzakelijk zijn om contact op te nemen met een logopediste.<br>
        Maar wanneer moet je je nu zorgen maken?<br>
        <ul>
          <li>Als opdrachten niet goed worden begrepen</li>
          <li>Je kind maakt geen of zeer korte zinnen</li>
          <li>Wanneer je kind moeilijk een verhaal kan vertellen</li>
          <li>Stotteren aanwezig is</li>
          <li>Je kind is eerder geremd of heeft angst om te spreken</li>
          <li>Niet alle klanken goed worden uitgesproken</li>
          <li>Je kind is heel moeilijk verstaanbaar</li>
        </ul>

        <span class="important-indications-title">Enkele belangrijke indicaties:</span>
        <table class="indications-list">
          <tr>
            <td>10 maanden:</td>
            <td>Jouw kind brabbelt nog weinig</td>
          </tr>
          <tr>
            <td>18 maanden:</td>
            <td>Nog geen woordjes of spreekt met enkel klinkers.<br>Begrijpt weinig taal</td>
          </tr>
          <tr>
            <td>2 tot 2,5 jaar</td>
            <td>Jouw kind kan nog geen tweewoordzinnen maken</td>
          </tr>
          <tr>
            <td>3 tot 4 jaar</td>
            <td>Er zijn nog geen korte zinnetjes aanwezig (bv. ik water drinken).<br>Jouw kind is grotendeels
              onverstaanbaar
            </td>
          </tr>
          <tr>
            <td>4 tot 4,5 jaar</td>
            <td>Het vertellen van een verhaaltje, gebeurtenis is nog zeer moeilijk.<br>Er zijn nog veel klanken moeilijk
              uit te spreken
            </td>
          </tr>
          <tr>
            <td>5 jaar</td>
            <td>Maakt nog geen goede zinnen</td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component
export default class WhenToContactSpeechTherapistsOnHome extends Vue {
  // Data

  // Lifecycle

  // Interface methods
  // Getters & setters
  // Helper methods
}
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

.important-indications-title {
  display: block;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.indications-list {
  border-collapse: separate;
  border-spacing: 0 1rem;

  tr {
    padding-bottom: 1rem;
  }

  tr :first-child {
    padding-right: 6rem;
    font-weight: bold;
    white-space: nowrap;
  }

  @media only screen and (max-width: $medium-screen-bp) {
    tr :first-child {
      padding-right: 2rem;
      font-weight: bold;
    }
  }
}


</style>
