<template>
  <div class="profile-container" @click="navigateToPerson(aboutUsId)">
    <div :style="photoAsCssBackground" class="photo"></div>
    <h5 class="name">{{ this.name }}</h5>
    <span class="job">{{ this.job }}</span>
  </div>
</template>
h
<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class ProfileOnHome extends Vue {
  // Data
  @Prop({required: true}) aboutUsId!: string
  @Prop({default: ''}) photoUrl!: string
  @Prop({default: ''}) name!: string
  @Prop({default: ''}) job!: string
  @Prop({default: 'center'}) photoPosition!: string;

  public get photoAsCssBackground() {
    // eslint-disable-next-line
    // return `background-image: url(${$images['example.png']});background-position: ${this.photoPosition}`
    // return `background-image: url("${require(this.photoUrl)}");background-position: ${this.photoPosition}`;
    return `background-image: url("${this.photoUrl}");background-position: ${this.photoPosition}`;
  }

  // Lifecycle


  // Interface methods

  backgroundStyle() {
    // Use a computed property to dynamically generate the background image style
    return {
      backgroundImage: `background-image: url(${require(`@/assets/public${this.photoUrl}`)});background-position: ${this.photoPosition}`
    };
  }

  // Getters & setters

  navigateToPerson(id: string) {
    const href = `/over-ons#${id}`;
    this.$router.push({path: href})
  }

  // Helper methods
}
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

$profileWidth: 250px;

.profile-container {
  display: inline-grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 1fr;
  width: $profileWidth;

  @media only screen and (max-width: $small-screen-bp) {
    grid-template-columns: 1fr;
    grid-template-rows: $profileWidth auto;
  }

  .photo {
    display: inline-block;
    border-radius: 50%;
    width: $profileWidth;
    height: $profileWidth;
    background-size: cover;
  }

  .name {
    display: inline-block;
    font-size: 1.5rem;
    text-align: center;
  }

  .job {
    display: inline-block;
    font-size: 1rem;
    font-style: italic;
    text-align: center;
  }
}

.profile-container:hover {
  cursor: pointer;

  .name {
    text-decoration: underline;

  }
}

</style>
