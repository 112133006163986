<template>
  <div id="landing-component">
    <div class="overlay">
      <div class="text-container px-4">
        <div class="main-action">
        <span
            class="text-h4 text-md-h2 font-weight-bold slogan"
            style="font-family:  'Josefin Slab' !important;"
        >
          LEER, REFLECTEER EN DENK VOORUIT
        </span>
        </div>
        <div class="landing-notice">
          <v-btn :color="buttonColor" class="my-4" @click="navigateToContactPage()">Maak een asfpraak</v-btn>
        </div>

        <div class="landing-notice">
          Wij zoeken nog toffe collega's!<br/>
          Ben je geïnteresseerd in wat we doen en wil je graag deel uitmaken van ons team?
          <v-btn :color="buttonColor" class="my-4" @click="navigateToVacancyPage()">Bekijk vacature</v-btn>
        </div>
      </div>
      <div class="values-container">
        <h3>Onze aanpak </h3>
        <v-carousel
            v-model="currentCarouselSlide"
            :show-arrows="false"
            cycle
            height="200"
            hide-delimiter-background
            interval="5000"
            light
        >
          <v-carousel-item
              v-for="(quote, i) in values"
              :key="i"
              :value="i"
          >
            <v-sheet color="transparent" height="100%" tile>
              <div class="d-flex fill-height justify-center align-center">
                <div class="quote-container">
                  <quote>{{ quote }}</quote>
                </div>
              </div>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
    <div :style="{backgroundImage: `url(${backgroundImageUrl})`}" class="img-container"></div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Quote from "@/components/Quote.vue";
import {$pkt_orange_light_0_opacity_09} from "@/scss/variables";

@Component({
  components: {Quote},
  props: {
    msg: String,
  },
})
export default class Landing extends Vue {
  // Data
  msg!: string;
  buttonColor = "rgba(248, 237, 223, 0.9)";
  currentCarouselSlide = 0;
  values: string[] = [
    "Streven naar een positief zelfbeeld en meer zelfvertrouwen",
    "Ieder kind is uniek, met zijn/haar sterktes en zwaktes",
    "Werken aan een mooie toekomst op maat van ieder kind",
  ]

  backgroundImageUrl = $pkt_orange_light_0_opacity_09

  mounted() {
    this.loadBackgroundImg();
  }

  // Interface methods

  public navigateToContactPage() {
    this.$router.push({path: "/contact"})
  }

  public navigateToVacancyPage() {
    this.$router.push({path: "/vacature"})
  }

  public async loadBackgroundImg() {
    const img = new Image();
    const imageUrls = ["/img/KinderenHomePage.webp", "/img/KinderenHomePage.jpg"];
    const realImageUrls = ["../public/img/KinderenHomePage.webp", "../public/img/KinderenHomePage.jpg"];

    for (let i = 0; i < imageUrls.length; i++) {
      // Set the src attribute of the Image object
      img.src = imageUrls[i];

      // Wait for the image to load
      try {
        await img.decode();
        // Once the image is loaded, update the data property and break out of the loop
        this.backgroundImageUrl = imageUrls[i];
        console.debug(`Image format ${imageUrls[i]} is supported.`)
        break;
      } catch (error) {
        // If the image format is not supported, catch the error and continue to the next format
        console.debug(`Image format ${imageUrls[i]} is not supported.`);
      }
    }

    this.backgroundImageUrl = img.src;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/scss/index.scss";

#landing-component {
  display: grid;
  flex: 1;
  align-items: center;
  width: 100%;
  position: relative;

  .main-action {

  }

}

.img-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100vh - $navbar-height);
  height: 100%;
  width: 100vw;
  //background: url("@/assets/KinderenHomePage.jpg");
  //background: url("../../public/img/KinderenHomePage.webp"), url("../../public/img/KinderenHomePage.jpg");
  background-position: center !important;
  background-size: cover;
  //background-size: 100% 100%;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    //background: url("../../public/img/KinderenHomePage.webp"), url("../../public/img/KinderenHomePage.jpg");
    //background: url("@/assets/KinderenHomePage.jpg");
    background-size: cover;
  }

  @media only screen and (max-width: $small-screen-bp) {
    //height: 940px;
  }
}

.slogan {
  display: block;
  text-align: center;
}

.overlay {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  align-content: space-around;

  height: 100%;
  width: 100%;
  z-index: 2;
}

.text-container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 6rem;
  justify-items: center;
  opacity: 1;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 4rem;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-bottom: 100px;
  }

  .landing-notice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.values-container {

  h3 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .quote-container {
    z-index: 1;
    margin: 0 auto;
    padding: 0 1rem;

    @media only screen and (max-width: $medium-screen-bp) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
      grid-gap: 2.5rem;
    }

  }

}
</style>
